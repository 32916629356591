<template>
    <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialog' :isdeling='isdeling'>
        <p class="text">
            该科目已有数据，删除后，本科目的数据将转入上级科目 <span style="color: #4F71FF">{{tipData.code}}</span> <span style="color: #4F71FF">{{tipData.name}}</span> 替代，您确定要继续吗？
        </p>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
export default {
  props: {
    tipData:{
      type: Object,
    },
    type:{
      type: String,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      title : this.getToken('subjectTab') || '资产类',
      config: {
        top: '20vh',
        width: '21rem',
        title: '提示',
        center: true,
        btnTxt: ['确定', '取消'],
      },
    };
  },
  methods: {
    beforeClose(){
      console.log('关闭前');
    },
  }
};
</script>

<style lang="less" scoped>
  .title{
    color: #FF0000;
    font-size: 16px;
  }
  .text{
    color: #333333;
    font-size: 14px;
    margin: 6px 0;
  }
</style>
